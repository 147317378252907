import React from 'react'

import styled from 'styled-components';
import { CardContainer, CardTitle, CardSubtitle } from "./Card"
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const StyledSponsorList = styled.div`
    display: block;
`

const StyledSponsorItemCard = styled.div`
    height: 82px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const SponsorImage = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-left: 20px;
`

const SponsorText = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: 20px;
`

const SponsorTitle = styled.div`
    font-size: 18px;
    font-weight: 900;
`

const SponsorSubtitle = styled.div`
    font-size: 16px;
`

const SponsorItemCard = function ({ sponsor }) {
    return (
        <StyledSponsorItemCard>
            <a href={sponsor.sponsorUrl}>
                <SponsorImage src={sponsor.imageUrl} />
            </a>
            <SponsorText>
                <SponsorTitle>{sponsor.title}</SponsorTitle>
                <SponsorSubtitle>{sponsor.subtitle}</SponsorSubtitle>
            </SponsorText>
            {/* <ChevronRightIcon style={{ height: "24px", width: "24px", marginLeft: "auto", marginRight: "20px" }} /> */}
        </StyledSponsorItemCard>
    )
}

const SponsorList = function ({ sponsors }) {
    return (
        <StyledSponsorList>
            {sponsors.map((sponsor, idx) => {
                return <SponsorItemCard key={idx} sponsor={sponsor} />
            })}
        </StyledSponsorList>
    )
}

export default function SponsorsCard({ sponsors }) {
    return (
        <CardContainer>
            <CardTitle>
                Show Sponsors
            </CardTitle>
            <CardSubtitle>
                Discount codes are automatically applied. Only through Storyline.
            </CardSubtitle>
            <SponsorList sponsors={sponsors} />
        </CardContainer>
    )
}