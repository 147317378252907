import React, { useState } from 'react';
import {
    useQuery, gql
} from '@apollo/client';
import styled from 'styled-components';
import { ReactComponent as EpisodeActions } from "./episodeActions.svg";
import AudioPlayer from "./AudioPlayer.js"
import {
    useParams
} from "react-router-dom";
import { SwipeableDrawer } from '@material-ui/core';
import { ProductCard, SubscribeCard, SponsorsCard } from './feed'


const GET_EPISODE = gql`
    query Episode($id: ID!) {
        episode(where: {id: $id}) {
            id
            title
            description
            publicationDate
            audioUrl
            imageUrl
            podcast {
                id
                name
            }
            products {
                id
                title
                subtitle
                imageUrl
                price
                productUrl
            }
            sponsors {
                id
                name
                imageUrl
                title
                subtitle
                sponsorUrl
            }
            applePodcastsUrl
            spotifyPodcastsUrl
            youTubeUrl
            overcastUrl
        }
    }
`;

const StyledEpisodePage = styled.div`
    overflow: hidden;
    height: 620px; /* TODO: make responsive */
`

const EpisodeActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-right: 30px;
`

const EpisodeArt = styled.img`
    max-width: 33%;
    display: block;
    margin: auto;
`

const PodcastName = styled.p`
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: center;
    color: #828282;
`

const EpisodeTitle = styled.h1`
    font-family: Helvetica Neue;
    font-size: 30px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
`

const EpisodeMetadata = styled.p`
    font-family: Helvetica Neue;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: center;
    color: #828282;
`

const EpisodeDescription = styled.p`
    font-family: Helvetica Neue;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding: 0px 50px;
    overflow-y: hidden;
`

function EpisodePage() {
    const { id } = useParams();
    const { loading, error, data } = useQuery(GET_EPISODE, {
        variables: {
            id: id,
        },
        skip: !id,
    });
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    if (loading) return <p>Loading...</p>
    if (error) return <p>`Error: {error.name}`</p>

    const episode = data.episode;
    return (
        <>
            <StyledEpisodePage onScroll={() => setIsPanelOpen(true)}>
                <EpisodeActionsContainer>
                    <EpisodeActions />
                </EpisodeActionsContainer>
                <button onClick={() => setIsPanelOpen(true)}>Open</button>
                <EpisodeArt src={episode.imageUrl}></EpisodeArt>
                <PodcastName>{episode.podcast.name}</PodcastName>
                <EpisodeTitle>{episode.title}</EpisodeTitle>
                <EpisodeMetadata>July 16, 2019 • 36 min</EpisodeMetadata>
                <EpisodeDescription>{episode.description}</EpisodeDescription>
                <AudioPlayer controls src={episode.audioUrl} showTimeDisplay={true} />
            </StyledEpisodePage>
            <SwipeableDrawer
                open={isPanelOpen}
                onOpen={() => { setIsPanelOpen(true) }}
                onClose={() => { setIsPanelOpen(false) }}
                anchor="bottom"
                PaperProps={{ square: false }}
            >
                <div style={{ height: "650px" }}>
                    <ProductCard products={episode.products} />
                    <SubscribeCard
                        applePodcastsUrl={episode.applePodcastsUrl}
                        spotifyPodcastsUrl={episode.spotifyPodcastsUrl}
                        youTubeUrl={episode.youTubeUrl}
                        overcastUrl={episode.overcastUrl}
                    />
                    <SponsorsCard sponsors={episode.sponsors} />
                </div>
            </SwipeableDrawer>

        </>
    )
}

export default EpisodePage;