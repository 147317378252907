import styled from 'styled-components';

export const CardContainer = styled.div`
    width: 416px;
    /* height: 496px; */
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
`

export const CardTitle = styled.h2`
    font-size: 30px;
    font-weight: 900;
    color: rgb(0,0,0);
    margin-top: 20px;
`

export const CardSubtitle = styled.h2`
    font-size: 16px;
`