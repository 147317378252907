import React from 'react'

import styled from 'styled-components';

import { CardContainer, CardTitle } from './Card.js'

const ProductImage = styled.img`
    width: 173px;
    height: 201px;
    margin-top: 40px;
`

const ProductPrice = styled.p`
    margin-top: 18px;
    font-size: 18px;
    font-weight: 500px;
`

const ProductTitle = styled.p`
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(40, 45, 52);
    max-width: 173px;
`

const ProductDescription = styled.p`
    margin-top: 0px;
    font-size: 16px;
    max-width: 173px;
`

const CarouselList = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
`

const StyledProduct = styled.div`
    display: inline-block;
    margin-right: 20px;
`

export default function ProductCard({ products }) {
    return (
        <CardContainer>
            <CardTitle>
                Featured Items
            </CardTitle>
            <CarouselList>
                {products.map((product, idx) => {
                    return <Product key={idx} product={product} />
                })}
            </CarouselList>
        </CardContainer>
    )
}

export const Product = ({ product }) => {
    const { imageUrl, price, title, subtitle, productUrl } = product;
    return <StyledProduct>
        <a href={productUrl}>
            <ProductImage src={imageUrl}></ProductImage>
        </a>
        <ProductPrice>{price}</ProductPrice>
        <ProductTitle>{title}</ProductTitle>
        <ProductDescription>{subtitle}</ProductDescription>
    </StyledProduct>
}