import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';

const LIST_PODCASTS = gql`
query {
  podcasts {
    id
    name
    episodes {
      id
      title
      publicationDate
      imageUrl
      audioUrl
    }
  }
}
`;

export const EpisodeList = ({ podcast }) => {
    console.log(podcast)
    const episodeList = podcast.episodes.map((e) => {
        return (<li key={e.id}><Link to={"/episode/" + e.id}>{e.title}</Link></li>)
    });

    return (
        <div>
            <h3>{podcast.name}</h3>
            <ul>
                {episodeList}
            </ul>
        </div>
    )
}

export const HomePage = () => {
    const { loading, error, data } = useQuery(LIST_PODCASTS);

    if (loading) return <p>Loading...</p>
    if (error) return <p>`Error: {error}`</p>

    const podcasts = data.podcasts.map((p) =>
        <EpisodeList key={p.name} podcast={p} />
    )
    return (
        <div>
            <h1>Storyline</h1>
            {podcasts}
        </div>
    )
}
