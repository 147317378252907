import React from 'react';
import ReactDOM from 'react-dom';
// import styled from 'styled-components'
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import EpisodePage from './EpisodePage';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom"
import { HomePage } from './HomePage';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: 'https://api-uswest.graphcms.com/v1/ck6pgyyvc463401fm2o7kg1yx/master',
    })
});

// const Background = styled.div`
//     width: 100%;
//     background-color: #ffffff;
//     box-sizing: border-box;
// `

const App = () => (
    <Router>
        <ApolloProvider client={client}>
            {/* <Background> */}
            <Switch>
                <Route path="/episode/:id">
                    <EpisodePage />
                </Route>
                <Route path="/">
                    <HomePage />
                </Route>
            </Switch>
            {/* </Background> */}
        </ApolloProvider>
    </Router>
);

ReactDOM.render(
    <App />,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
