import React, { useState, useEffect, useRef } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Replay10Icon from '@material-ui/icons/Replay10';
import Forward30Icon from '@material-ui/icons/Forward30';
import { Duration } from 'luxon';
import styled from 'styled-components';

const PLAYBACK_SPEEDS = [1, 1.5, 2]

const StyledAudioControls = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
`

export const Play = ({ className, onClick, text }) => {
    return (
        <button onClick={onClick} className={className}>
            {text}
        </button>
    )
}

const StyledAudioControlButton = styled.button`
    padding: 0;
    border: none;
    background: none;
    width: 50px;
    outline: none;
`

const ProgressBarContainer = styled.div`
    width: 100%;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 20px;
    height: 5px;
    background-color: rgb(171, 171, 171, 0.2);
`

const ProgressBarTimerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(51, 50, 50);
    font-size: 12px;
`

const ProgressBar = styled.div`
    width: ${props => props.pct + "%"};
    height: 5px;
    background-color: #50e3c2;
`

// const ProgressBarCircle = styled.div`
//     background-color: #50e3c2;
//     width: 20px;
//     height: 20px;
//     border-radius: 50%;
//     position: relative;
//     top: -7px;
// `


const formatDuration = (secs) => {
    const d = Duration.fromObject({ seconds: secs });
    return d.minutes >= 60 ? d.toFormat("hh:mm:ss") : d.toFormat("mm:ss");
}

const FixedContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: white;
`

export default function AudioPlayer({ src, showTimeDisplay }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackSpeedIdx, setPlaybackSpeedIdx] = useState(0)
    const [currentTime, setCurrentTime] = useState(0.0)
    const [duration, setDuration] = useState(undefined)

    let audioRef = useRef(null);

    const handlePlayPause = (e) => {
        console.log("play")
        e.preventDefault();
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }

        setIsPlaying(!isPlaying);
    }

    const handleSpeedToggle = (e) => {
        e.preventDefault();
        const nextPlaybackSpeedIdx = (playbackSpeedIdx + 1) % PLAYBACK_SPEEDS.length;
        audioRef.current.playbackRate = PLAYBACK_SPEEDS[nextPlaybackSpeedIdx];

        setPlaybackSpeedIdx(nextPlaybackSpeedIdx);
    }

    const seek = (seconds) => {
        audioRef.current.currentTime += seconds
    }

    const seekForward = () => { seek(30) }
    const seekBackward = () => { seek(-10) }

    useEffect(() => {
        const intervalID = setInterval(() => {
            setCurrentTime(audioRef.current.currentTime);

            // TODO: only set this once after audio src loads
            setDuration(audioRef.current.duration);
        }, 100);

        return () => {
            clearInterval(intervalID);
        }
    })

    const timerContainer = showTimeDisplay ? (
        <ProgressBarTimerContainer>
            <div>{formatDuration(currentTime)}</div>
            <div>{duration ? formatDuration(duration) : ""}</div>
        </ProgressBarTimerContainer>
    ) : null;

    return (
        <div>
            <audio src={src} ref={audioRef} />
            <FixedContainer>
                <ProgressBarContainer>
                    <ProgressBar pct={(currentTime / duration) * 100}>
                        {/* <ProgressBarCircle /> */}
                    </ProgressBar>
                    {timerContainer}
                    {/* <ProgressBarTimerContainer>
                        <div>{formatDuration(currentTime)}</div>
                        <div>{duration ? formatDuration(duration) : ""}</div>
                    </ProgressBarTimerContainer> */}
                </ProgressBarContainer>
                <StyledAudioControls>
                    <StyledAudioControlButton></StyledAudioControlButton>
                    <StyledAudioControlButton onClick={seekBackward}>
                        <Replay10Icon fontSize="large" />
                    </StyledAudioControlButton>
                    <StyledAudioControlButton onClick={handlePlayPause}>
                        {isPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
                    </StyledAudioControlButton>
                    <StyledAudioControlButton onClick={seekForward}>
                        <Forward30Icon fontSize="large" />
                    </StyledAudioControlButton>
                    <StyledAudioControlButton onClick={handleSpeedToggle} style={{ fontSize: "21px", fontWeight: "bold" }}>
                        {PLAYBACK_SPEEDS[playbackSpeedIdx] + "x"}
                    </StyledAudioControlButton>
                </StyledAudioControls>
            </FixedContainer>
        </div>
    )
}
