import React from 'react'

import styled from 'styled-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CardContainer, CardTitle, CardSubtitle } from './Card.js'

// const SubscribeTitle = styled.p`
//     margin-top: 10px;
//     font-size: 18px;
//     font-weight: 600;
//     color: rgb(40, 45, 52);
//     max-width: 173px;
// `

const PodcastAppContainer = styled.div`
    width: 374px;
    height: 92px;
    border-radius: 8px;
    border: solid 1px #e4e4e4;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const PodcastAppImage = styled.img`
    height: 60px;
    width: 60px;
    margin-left: 20px;
    max-height: 60px;
    border-radius: 10px;
    align-self: center;
    /* flex-basis: 20%; */
`

const PodcastAppName = styled.p`
    font-size: 20px;
    font-weight: 900;
    line-height: 1.15;
    vertical-align: middle;
    margin-left: 26px;
    /* margin-right: 60px; */
    /* flex-basis: 60%; */
`

const PodcastAppDeeplinkCard = function ({ imageUrl, appName, link }) {
    return (
        <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "inherit" }}>
            <PodcastAppContainer style={{ marginBottom: "15px" }}>
                <PodcastAppImage src={imageUrl}></PodcastAppImage>
                <PodcastAppName>{appName}</PodcastAppName>
                <ChevronRightIcon style={{ height: "24px", width: "24px", marginLeft: "auto", marginRight: "20px" }} />
            </PodcastAppContainer>
        </a>
    )
}

export default function SubscribeCard({ applePodcastsUrl, spotifyPodcastsUrl, youTubeUrl, overcastUrl }) {
    const spotifyCard = spotifyPodcastsUrl ?
        <PodcastAppDeeplinkCard
            imageUrl="https://cdn.jim-nielsen.com/ios/512/spotify-music-2015-07-30.png"
            appName="Spotify"
            link={spotifyPodcastsUrl}
        /> : null;

    const appleCard = applePodcastsUrl ?
        <PodcastAppDeeplinkCard
            imageUrl="https://static1.squarespace.com/static/5735ef9da3360ce2540895c5/5735f94a2fe131630fbc8d7f/589d5171be65941098d7de22/1497534099175/Podcast+Icon.jpg?format=1500w"
            appName="Apple Podcasts"
            link={applePodcastsUrl}
        /> : null;

    const youtubeCard = youTubeUrl ?
        <PodcastAppDeeplinkCard
            imageUrl="https://cdn4.iconfinder.com/data/icons/social-media-2210/24/Youtube-512.png"
            appName="YouTube Channel"
            link={youTubeUrl}
        /> : null;

    const overcastCard = overcastUrl ?
        <PodcastAppDeeplinkCard
            imageUrl="https://overcast.fm/img/appicon-3200.png"
            appName="Overcast"
            link={overcastUrl}
        /> : null;

    console.log(spotifyPodcastsUrl, applePodcastsUrl, youTubeUrl)
    return (
        <CardContainer>
            <CardTitle>
                Subscribe
            </CardTitle>
            <CardSubtitle>
                Support <span style={{ fontWeight: "bold" }}>Conscious Creators</span> and subscribe to their show!
            </CardSubtitle>
            {/* TODO: bundle app icon assets */}
            {appleCard}
            {spotifyCard}
            {youtubeCard}
            {overcastCard}
        </CardContainer>
    )
}